<!-- The video -->
<video playsinline autoplay muted loop poster="my-video.jpg">    
    <source src="/assets/videos/bottling-factory.mp4">
  </video> 
  
  <!-- Optional: some overlay text to describe the video -->
  <div class="content">
    <h1>Heading</h1>
    <p>Lorem ipsum...</p>
    <!-- Use a button to pause/play the video with JavaScript -->
    <button id="myBtn" onclick="myFunction()">Pause</button>
  </div>






