import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { MainComponent } from './pages/main/main.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DataTableModule } from './pages/apps/data-table/data-table.module';
import { PricingModule } from './pages/pricing/pricing.module';


const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboard',
        redirectTo: '/'
      },
      {
        path: 'graph',
        loadChildren: () => import('./pages/graph/graph.module').then(m => m.GraphModule),
      },
      {
        path: 'subscription',
        loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule),
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },

      {
        path: 'data',
        children: [
          {
            path: 'data-table',
            loadChildren: () => import('./pages/apps/data-table/data-table.module').then(m => m.DataTableModule),
          }
        ]
    
      },
    ]
  },

  {
    path: 'main',
    component: MainComponent,
    children: []
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
