<div class="body-2 p-6 bg-primary text-primary-contrast">
  <p>TODAY</p>
  <div class="display-1">{{ dayName }}</div>
  <div class="display-1">{{ date }}</div>
</div>

<mat-divider></mat-divider>

<mat-nav-list>
  <h3 matSubheader>FAULTS</h3>
  
  <mat-divider></mat-divider>
  <h3 matSubheader>WARNINGS</h3>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>ACS 880 Not connected</h4>
    <p matLine>1:00 PM 8/1/2021</p>
  </a>
  
  <h3 matSubheader>SERVER STATISTICS</h3>
</mat-nav-list>

<div class="list-item" matRipple>
  <p>Number of Devices (2 of 5)</p>
  <p class="progress-bar">
    <mat-progress-bar color="primary" mode="determinate" value="2"></mat-progress-bar>
  </p>
</div>


<div class="list-item" matRipple>
  <p>Data Usage (1%)</p>
  <p class="progress-bar">
    <mat-progress-bar color="warn" mode="determinate" value="1"></mat-progress-bar>
  </p>
</div>
