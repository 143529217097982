// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCvBuQp7BTEfPuHYsUy-vMWjDU0yFw2Ghc",
    authDomain: "iiot-1b29f.firebaseapp.com",
    projectId: "iiot-1b29f",
    storageBucket: "iiot-1b29f.appspot.com",
    messagingSenderId: "311618833351",
    appId: "1:311618833351:web:dba03c0bffb369527167e2",
    measurementId: "G-61Z2HW8LNB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
